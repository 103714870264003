import _axios from 'axios';
import { getBaseURL } from './common';
import { notFound } from 'next/navigation';

const generateAxios = () => {
  const axios = _axios.create({ baseURL: getBaseURL() });
  axios.defaults.withCredentials = true;

  axios.interceptors.request.use(function (config) {
    if (typeof window !== 'undefined') {
      config.headers.prefix = window.location.hostname;
    }
    return config;
  });

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (typeof window !== 'undefined' && error.response && error.response.data && location.pathname !== '/404') {
        switch (error.response.data.code) {
          case 'NotFoundDomainException': {
            notFound();
          }
          case 'CannotFindPositionException': {
            notFound();
          }
          case 'NotFoundPostedDesignException': {
            notFound();
          }
        }
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  );

  return axios;
};

const axios = generateAxios();
const recruiterAxios = generateAxios();

// 사용되지 않음
// export const setAxiosHeader = (key: string, value: string) => {
//   axios.defaults.headers.common[key] = value;
// };

export { axios, recruiterAxios };
