import { SolutionType } from 'ui/constants/solution/solution.constant';
import { EnvType } from 'ui/constants/env.constant';
import { AppType } from '../../constants/app.constant';
import { SOLUTION_TYPE } from '../../util/solution';
import { APP_TYPE } from '../../util/app';

export const API_URL: Record<AppType, Record<SolutionType, Record<EnvType, string>>> = {
  EDITOR: {
    JOBDA: {
      production: 'https://api.builder.match.jobda.im',
      staging: 'https://api.builder.ats.kr-st-jainwon.com',
      staging2: 'https://st2-api.builder.ats.kr-st-jainwon.com',
      development: 'https://api.builder.ats.kr-dv-jainwon.com',
    },
    JOBFLEX: {
      production: 'https://api-builder.recruiter.co.kr',
      staging: 'https://api.builder.kr-st-midasitwebsol.com',
      staging2: 'https://api.builder.kr-st2-midasitwebsol.com',
      development: 'https://api.builder.kr-dv-midasitwebsol.com',
    },
  },
  RECRUITER: {
    JOBDA: {
      production: 'https://api.recruiter.im',
      staging: 'https://api.recruiter.ats.kr-st-jainwon.com',
      staging2: 'https://st2-api.recruiter.ats.kr-st-jainwon.com',
      development: 'https://api.recruiter.ats.kr-dv-jainwon.com',
    },
    JOBFLEX: {
      production: 'https://api-recruiter.recruiter.co.kr',
      staging: 'https://api.recruiter.kr-st-midasitwebsol.com',
      staging2: 'https://api.recruiter.kr-st2-midasitwebsol.com',
      development: 'https://api.recruiter.kr-dv-midasitwebsol.com',
    },
  },
};

export const getBaseURL = () => {
  // eslint-disable-next-line turbo/no-undeclared-env-vars
  const env = process?.env?.NEXT_PUBLIC_ENV ?? 'development';
  // eslint-disable-next-line turbo/no-undeclared-env-vars

  return API_URL[APP_TYPE][SOLUTION_TYPE][env as EnvType];
};
